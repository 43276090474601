import * as React from 'react';
import { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import styled from '@emotion/styled';
import { mqs } from '../components/style-utils';
import { usePositions } from '../components/wrapper-positions';
import SEO from '../components/seo';
import { FlexGrid } from '../components/grid';
import { ArtistBlock } from '../components/home/artist-block';
const StyledUl = styled.ul `
  min-height: 80vh;

  li > div {
    opacity: 1;
  }

  &.is-hovering li > div {
    opacity: 0.75;
  }

  li > div:hover {
    opacity: 1;
  }

  ${mqs[0]} {
    padding-top: 12rem;
  }

  ${mqs[1]} {
    padding-top: 12rem;
  }
`;
const NoticeIndex = ({ data, pageContext }) => {
    const { title: siteTitle } = data.site.siteMetadata;
    const { nodes: artists } = data.allArtist;
    const { lang } = pageContext;
    const { positions, gridSize, order } = usePositions();
    const [widthSize, setWidthSize] = useState(0);
    const [leftPos, setLeftPos] = useState(0);
    useEffect(() => {
        const $layoutWrapper = document.querySelector('#grid-size-root');
        const rect = $layoutWrapper.getBoundingClientRect();
        const widthSize = $layoutWrapper ? rect.width : 0;
        const leftPos = $layoutWrapper ? rect.left : 0;
        setLeftPos(leftPos);
        setWidthSize(widthSize);
    }, [gridSize]);
    /* don't render this list SSR
     * until figure out how to handle
     * mismatch
     */
    const shuffledArtists = typeof window === 'undefined'
        ? []
        : order.map(id => artists[id] || null).filter(v => v);
    return (<React.Fragment>
      <SEO title={`${siteTitle} | Artists`} lang={lang}/>
      <StyledUl className="artists-list">
        {shuffledArtists.map((artist, i) => {
        const position = positions[i];
        return (<FlexGrid as="li" key={i}>
              <ArtistBlock lang={lang} artist={artist} gridSize={gridSize} leftPos={leftPos} widthSize={widthSize} begin={position.begin} end={position.end} index={i}/>
            </FlexGrid>);
    })}
      </StyledUl>
    </React.Fragment>);
};
export default NoticeIndex;
export const query = graphql `
  query HomeArtists {
    site {
      siteMetadata {
        title
      }
    }
    allArtist {
      nodes {
        fields {
          slug
        }
        nameKo
        nameEn
        nameJa
        nameZh
        profilePhoto {
          squareFile {
            childImageSharp {
              fluid(
                maxWidth: 500
                sizes: "(max-width: 480px) 320px, (max-width: 750px) 500px, (max-width: 900px) 500px, (max-width: 1280px) 320px, 320px"
                quality: 75
              ) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
          landscapeFile {
            childImageSharp {
              fluid(
                maxWidth: 960
                quality: 75
                sizes: "(max-width: 480px) 320px, (max-width: 750px) 500px, (max-width: 900px) 800px, (max-width: 1280px) 960px, 800px"
              ) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
      }
    }
  }
`;
